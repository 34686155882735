<template>
  <div>
    <v-card>
      <v-progress-linear v-if="isLoading" color="primary" indeterminate></v-progress-linear>
      <v-card-title class="text-h4">
        {{ name }}
      </v-card-title>
      <v-card-text>
        <v-form
            ref="form"
            v-model="valid"
            class="form"
            lazy-validation
        >
          <v-row>
            <v-col v-for="category in _formFields" v-bind:key="category.name" :md="category.md" cols="12">
              <v-card :color="category.color" :flat="category.flat">
                <v-card-title v-if="category.name" class="text-h5">
                  {{ category.name }}
                </v-card-title>
                <v-card-text>
                  <v-row class="mb-3">
                    <v-col v-for="col in category.cols" v-bind:key="col.id" :md="col.md" cols="12"
                           v-on:input="saveLocalStorageData()">
                      <div>
                        <v-checkbox v-if="col.type === 'checkbox'" v-model="col.model" :color="col.color"
                                    :label="col.label" :rules="col.rules" @change="saveLocalStorageData()"/>
                        <v-text-field v-if="col.type === 'textField'" v-model="col.model" :counter="col.counter"
                                      :label="col.label" :required="col.required" :rules="col.rules"
                                      :type="col.fieldType"/>
                        <v-textarea v-if="col.type === 'textarea'" v-model="col.model" :auto-grow="col.autoGrow"
                                    :counter="col.counter" :label="col.label" :required="col.required"
                                    :rules="col.rules"/>
                        <v-autocomplete v-if="col.type === 'autocomplete'" v-model="col.model" :auto-grow="col.autoGrow"
                                        :counter="col.counter" :item-text="col.itemText" :items="col.items" :label="col.label"
                                        :rules="col.rules" return-object
                                        @change="saveLocalStorageData()"/>
                        <v-combobox v-if="col.type === 'combobox'" v-model="col.model" :auto-grow="col.autoGrow"
                                    :counter="col.counter" :item-text="col.itemText" :items="col.items" :label="col.label"
                                    :rules="col.rules" :search-input.sync="col.search" return-object
                                    @change="col.itemFoundInItems = trueIfModelIsInData(col.model, col.items); saveLocalStorageData(); ">
                          <template v-slot:no-data>
                            <v-list-item>
                              <v-list-item-content>
                                <v-list-item-title>
                                  Es wurde kein Eintrag mit "<strong>{{ col.search }}</strong>" gefunden. Drücke <kbd>enter</kbd>
                                  um einen neuen Eintrag zu erstellen
                                </v-list-item-title>
                              </v-list-item-content>
                            </v-list-item>
                          </template>
                        </v-combobox>
                        <v-menu v-if="col.type === 'datePicker'">
                          <!-- v-model="col.model" :label="col.label" :rules="col.rules" -->
                        </v-menu>
                      </div>
                      <v-row v-if="!col.itemFoundInItems && col.type === 'combobox'">
                        <v-col v-for="createFields in col.createFields" v-bind:key="createFields.name" :md="createFields.md"
                               cols="12" v-on:input="saveLocalStorageData()">
                          <v-checkbox v-if="createFields.type === 'checkbox'" v-model="createFields.model"
                                      :color="createFields.color" :label="createFields.label"
                                      :rules="createFields.rules" @change="saveLocalStorageData()"/>
                          <v-text-field v-if="createFields.type === 'textField'" v-model="createFields.model"
                                        :counter="createFields.counter" :label="createFields.label"
                                        :required="createFields.required" :rules="createFields.rules"
                                        :type="createFields.fieldType"/>
                          <v-textarea v-if="createFields.type === 'textarea'" v-model="createFields.model"
                                      :auto-grow="createFields.autoGrow" :counter="createFields.counter"
                                      :label="createFields.label" :required="createFields.required"
                                      :rules="createFields.rules"/>
                          <v-autocomplete v-if="createFields.type === 'autocomplete'" v-model="createFields.model"
                                          :auto-grow="createFields.autoGrow" :counter="createFields.counter" :item-text="createFields.itemText"
                                          :items="createFields.items" :label="createFields.label"
                                          :rules="createFields.rules" return-object
                                          @change="saveLocalStorageData()"/>
                          <!-- Combobox in Combobox -->
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions class="justify-end">
        <v-spacer></v-spacer>
        <v-dialog
            :fullscreen="$vuetify.breakpoint.smAndDown"
            max-width="600"
            transition="dialog-top-transition"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn :disabled="isLoading" color="warning" v-bind="attrs"
                   v-on="on">Formular Leeren
            </v-btn>
          </template>
          <template v-slot:default="dialog">
            <v-card>
              <v-toolbar
                  color="primary"
                  dark
              >Formular zurücksetzen?
              </v-toolbar>
              <v-card-text class="mt-4">
                <div class="text-body-1">Wollen Sie wirklich das Formular zurücksetzen?</div>
                <div class="text-body-1">Alle bereits eingegebenen Daten gehen verloren.</div>
              </v-card-text>
              <v-card-actions class="justify-end">
                <v-btn
                    color="error"
                    text
                    @click="dialog.value = false"
                >Abbrechen
                </v-btn>
                <v-btn :disabled="isLoading" color="warning" @click="resetForm(dialog)">Formular Leeren</v-btn>
              </v-card-actions>
            </v-card>
          </template>
        </v-dialog>
        <v-btn :disabled="isLoading" color="error" @click="cancle()">Abbrechen</v-btn>
        <v-btn :disabled="isLoading" color="info" @click="saveLocalStorageData()">Entwurf Speichern</v-btn>
        <v-btn :disabled="blockButtonWhileRequestAndForm()" :loading="isLoading" color="primary" @click="sentForm()">
          Absenden und zurück
        </v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import {sessionHandler} from "@/request/sessionHandler";
import {getContactsURL, requestMode} from "@/configBuilder";
import {showAlert} from "@/utils/alertHandler";
import i18n from "@/plugins/i18n";

export default {
  name: "TemplateForm",
  props: {
    update: {
      type: Boolean,
      default: false
    },
    name: {
      required: true,
      type: String
    },
    url: {
      required: true,
      type: String
    },
    localStorageKey: {
      required: true,
      type: String
    },
    formFields: {
      required: true,
      type: Array
    },
    currentCase: {
      type: Object
    }
  },
  data: () => ({
    valid: false,
    isLoading: false,
  }),
  methods: {
    cancle(){
      if (this.update){
        this.closeDialog();
      } else {
        this.$router.push("/")
      }
    },
    async updateItemLists(){
      this.isLoading = true;

      for (const [indexX, category] of this.formFields.entries()) {
        for (const [indexY, col] of category.cols.entries()) {
          if (col.type === "combobox") {
            await sessionHandler();
            await this.$http
                .get(
                    getContactsURL(),
                    {
                      mode: requestMode(),
                      headers: {
                        'Accept': 'application/json',
                        'authorization': this.$RStore.app.AuthenticationType + ' ' + this.$cookies.get('access_token'),
                      },
                    }
                )
                .then((response) => {
                  if (response.status === 200) {
                    this.formFields[indexX].cols[indexY].items = response.data;
                    this.isLoading = false;
                    this.syncHTMLRenderer();
                    showAlert(i18n.t('success.contacts'), "success");
                  } else {
                    showAlert(i18n.t('error.api.undefined') + this.localStorageKey + "-sF1", "error");
                  }
                })
                .catch((error) => {
                  if (error.response) {
                    if (error.response.status === 401) {
                      showAlert(i18n.t('warning.unauthorised'), "warning");
                    } else {
                      showAlert(i18n.t('error.api.undefined') + this.localStorageKey + "-sF2", "error");
                    }
                  } else {
                    showAlert(i18n.t('error.api.undefined') + this.localStorageKey + "-sF2", "error");
                  }
                })
                .finally(() => {

                });
          }
        }
      }
    },
    syncHTMLRenderer() {
      this.$forceUpdate();
      this.formFields.forEach(category => {
        category.cols.forEach(col => {
              if (col.type === 'combobox') {
                col.itemFoundInItems = this.trueIfModelIsInData(col.model, col.items, col.name)
              }
            });
          });
    },
    blockButtonWhileRequestAndForm() {
      if (this.$refs.form) {
        this.$refs.form.validate();
      }
      if (this.valid === false) {
        return true
      }
      return this.isLoading === true;
    },
    generateFormData(dataToConvert) {
      let formData = {};
      dataToConvert.forEach(category => {
        category.cols.forEach(col => {
          if (col.type === 'combobox') {
            col.itemFoundInItems = this.trueIfModelIsInData(col.model, col.items, col.name)
            if (col.itemFoundInItems) {
              formData[col.name] = col.model;
            } else {
              let createFieldData = {};
              createFieldData[col.itemText] = col.model;
              createFieldData["itemFoundInItems"] = col.itemFoundInItems;
              if (col.createFields) {
                col.createFields.forEach(createField => {
                  createFieldData[createField.name] = createField.model;
                });
              }
              formData[col.name] = createFieldData
            }
          } else {
            formData[col.name] = col.model;
          }
        });
      });
      return formData;
    },
    trueIfModelIsInData(model, items) {
      if (!model) {
        return false
      }
      if (!items) {
        return false
      }
      let found = false;
      items.forEach(item => {
        if (item.id === model.id) {

          found = true;
        }
      });
      return found;
    },
    closeDialog() {
      this.$emit("closeDialog");
    },
    async checkForItemNotFoundInItemsAndCreate() {
      for (const [indexX, category] of this.formFields.entries()) {
        for (const [indexY, col] of category.cols.entries()) {
          if (col.type === 'combobox') {
            if (col.itemFoundInItems === false) {
              let formData = {};
              formData[col.itemText] = col.model;
              col.createFields.forEach(createField => {
                formData[createField.name] = createField.model;
              });
              await sessionHandler();

              await this.$http
                  .post(
                      col.createURL,
                      formData
                      , {
                        mode: requestMode(),
                        headers: {
                          'Accept': 'application/json',
                          'authorization': this.$RStore.app.AuthenticationType + ' ' + this.$cookies.get('access_token'),
                        },
                      }
                  )
                  .then((response) => {
                    if (response.status === 200) {
                      this.formFields[indexX].cols[indexY].itemFoundInItems = true;
                      this.formFields[indexX].cols[indexY].model = response.data;
                      showAlert(i18n.t('success.form'), "success");
                      // this.$router.push("/")
                    } else {
                      showAlert(i18n.t('error.api.undefined') + this.localStorageKey + "-sF1", "error");
                    }
                  })
                  .catch((error) => {
                    this.isLoading = false;
                    if (error.response) {
                      if (error.response.status === 401) {
                        showAlert(i18n.t('warning.unauthorised'), "warning");
                      } else {
                        showAlert(i18n.t('error.api.undefined') + this.localStorageKey + "-sF2", "error");
                      }
                    } else {
                      showAlert(i18n.t('error.api.undefined') + this.localStorageKey + "-sF2", "error");
                    }
                  })
                  .finally(() => {

                  })
            }
          }
        }
      }
    },
    async sentForm() {
      if (this.$refs.form.validate()) {
        this.isLoading = true;
        await this.checkForItemNotFoundInItemsAndCreate();
        await this.updateItemLists();
        let formData = this.generateFormData(this.formFields)
        await sessionHandler();
        if (this.update) {
          await this.$http
              .patch(
                  this.url + "/" + this.currentCase.id,
                  formData
                  , {
                    mode: requestMode(),
                    headers: {
                      'Accept': 'application/json',
                      'authorization': this.$RStore.app.AuthenticationType + ' ' + this.$cookies.get('access_token'),
                    },
                  }
              )
              .then((response) => {
                if (response.status === 200) {
                  this.clearLocalStorageData();
                  if (this.update){
                    this.closeDialog();
                    this.isLoading = false;
                  } else {
                    this.$router.push("/").then(()=> {    this.isLoading = false; });
                  }
                  showAlert(i18n.t('success.form'), "success");
                } else {
                  showAlert(i18n.t('error.api.undefined') + this.localStorageKey + "-sF1", "error");
                }
              })
              .catch((error) => {
                this.isLoading = false;
                if (error.response) {
                  if (error.response.status === 401) {
                    showAlert(i18n.t('warning.unauthorised'), "warning");
                  } else {
                    showAlert(i18n.t('error.api.undefined') + this.localStorageKey + "-sF2", "error");
                  }
                } else {
                  showAlert(i18n.t('error.api.undefined') + this.localStorageKey + "-sF2", "error");
                }
              })
              .finally(() => {
              })
        } else {
          await this.$http
              .post(
                  this.url,
                  formData
                  , {
                    mode: requestMode(),
                    headers: {
                      'Accept': 'application/json',
                      'authorization': this.$RStore.app.AuthenticationType + ' ' + this.$cookies.get('access_token'),
                    },
                  }
              )
              .then((response) => {
                if (response.status === 200) {
                  this.clearLocalStorageData();
                  if (this.update){
                    this.closeDialog();
                    this.isLoading = false;
                  } else {
                    this.$router.push("/").then(()=> {    this.isLoading = false; });
                  }
                  showAlert(i18n.t('success.form'), "success");
                } else {
                  showAlert(i18n.t('error.api.undefined') + this.localStorageKey + "-sF1", "error");
                }
              })
              .catch((error) => {
                this.isLoading = false;
                if (error.response) {
                  if (error.response.status === 401) {
                    showAlert(i18n.t('warning.unauthorised'), "warning");
                  } else {
                    showAlert(i18n.t('error.api.undefined') + this.localStorageKey + "-sF2", "error");
                  }
                } else {
                  showAlert(i18n.t('error.api.undefined') + this.localStorageKey + "-sF2", "error");
                }
              })
              .finally(() => {

              })
        }
      }
    },

    restoreLocalStorageData() {
      let data = localStorage.getItem(this.localStorageKey);
      if (data !== null && data !== undefined && data !== "") {
        data = JSON.parse(data);
        this.formFields.forEach(category => {
          category.cols.forEach(col => {
            if (col.type === 'combobox') {
              col.itemFoundInItems = this.trueIfModelIsInData(data[col.name], col.items, col.name)
              if (col.itemFoundInItems) {
                col.model = data[col.name]
              } else {
                col.model = data[col.name][col.itemText]
                if (col.createFields) {
                  col.createFields.forEach(createField => {
                    createField.model = data[col.name][createField.name]
                  });
                }
              }
            } else {
              col.model = data[col.name];
            }
          });
        });
        this.syncHTMLRenderer();
        if (this.$refs.form) {
          this.$refs.form.validate();
        }
      }
    },

    saveLocalStorageData() {
      localStorage.setItem(this.localStorageKey, JSON.stringify(this.generateFormData(this.formFields)))
      this.syncHTMLRenderer();
    },

    clearLocalStorageData() {
      localStorage.setItem(this.localStorageKey, "")
    },

    resetForm(dialog) {
      this.$refs.form.reset();
      this.clearLocalStorageData();
      dialog.value = false;
    }
  },
  computed: {
    _formFields() {
      return this.formFields;
    }
  },
  async mounted() {
    this.$refs.form.resetValidation();
    await this.updateItemLists();
    this.restoreLocalStorageData();
    this.valid = this.update;
  },
}
</script>

<style scoped>

</style>
